.contact-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 40px;
  width: 100%;
}

.map {
  flex: 1;
  margin-right: 20px;
  width: 50%;
}

.details {
  flex: 1;
  max-width: 400px;
  color: #000;
  margin-right: 20px;
  background-image: url(../../public/asset/images/element_26.png);
  background-size: cover;
  background-position: right;
  border-radius: 10px;
}
.details p {
  margin: 0 0 0 0;
  font-family: Roboto, "sans-serif";
}

.detail-row {
  display: flex;
  align-items: center;
  text-align: left;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 6px 54px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  padding: 30px 30px;
  margin-top: 20px;
}

.detail-row-1-txt {
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
}

.detail-row-1-txt-2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding-top: 10px;
}

.detail-row-2 {
  margin-left: 10px;
}

.detail-row-2-txt {
  margin-bottom: 0px;
}

.detail-row-2-txt a {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
  color: #000000;
}

.contact-form {
  display: flex;
  justify-content: space-between;
  /* align-items: flex-start; */  
  height: auto;
}

.contact-form .col-12 {
  margin-bottom: 15px;
}

.contact-content {
  flex: 1;
  max-width: 50%;
}
.contact-content h2 {
  font-family: sans-serif;
  color: rgb(0, 0, 0);
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
}

.contact-content p {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin: 10px 30px;
}

.contact-form input,
.contact-form textarea {
  /* margin-bottom: 15px; */
  padding: 10px;
  font-size: 16px;
  border: 1px solid #000000;
  border-radius: 4px;
  width: 100%;
  transition: none;
}

.contact-form input:focus {
  /* border: 0px; */
  box-shadow: 0px !important;
  /* transform: ; */
}

.contact-form .col-12 button {
  padding: 15px;
  font-size: 16px;
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  box-shadow: inset 0 0 0 0 #c89c31;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
  margin: 0 auto;
  width: 150px;
}

.contact-form .contact-btn-wrapper {
  display: inline-flex;
  align-items: center;
}
.contact-form .contactform-error {
  font-weight: 700;
  font-family: 14px;
}
.contact-form button:hover {
  box-shadow: inset 400px 0 0 0 #c89c31;
}
.form-image {
  flex: 1 1;
  width: 50%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  max-width: 40%;
}
.form-image img {
  max-width: 100%;
  height: 400px;
}

@media (max-width: 2400px) {
  .form-image img {
    height: 510px;
  }
  .details {
    max-width: 610px;
  }
}

@media (max-width: 1440px) {
  .form-image img {
    height: 441px;
  }
  .details {
    max-width: 500px;
  }
  .contact-form {
    align-items: center;
  }
}

@media (max-width: 1024px) {
  .container {
    margin-top: 140px;
  }
}
@media (max-width: 991px) {
  .container {
    margin-top: 35px;
  }
  .contact-container {
    margin-top: 40px !important;
  }
  .contact-info {
    flex-direction: column;
  }
  .map iframe {
    max-width: 100%;
    width: 100%;
  }
  .details h2 {
    text-align: left;
  }
  .detail-row {
    padding: 20px 20px;
    flex-direction: column;
  }
  .detail-row-2 {
    text-align: left;
    width: 100%;
  }
  .contact-form{
    flex-direction: column;
  }
  .contact-content {
    text-align: center;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
  .form-image {
    content-visibility: hidden;
  }
  .map,
  .details,
  .contact-content,
  .form-image {
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .container {
    margin-top: 0px;
  }
  .contact-container {
    margin-top: 0px !important;
  }
  .contact-info,
  .contact-form {
    flex-direction: column;
  }
  .details h2 {
    text-align: left;
  }
  .detail-row {
    padding: 20px 20px;
    flex-direction: column;
  }
  .detail-row-2 {
    text-align: left;
    width: 100%;
  }
  iframe {
    max-width: 100%;
    width: 100%;
  }
  .contact-content {
    text-align: center;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }

  .form-image {
    content-visibility: hidden;
  }
  .map,
  .details,
  .contact-content,
  .form-image {
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
  }
}

@media (max-width: 600px) {
  .contact-info,
  .contact-form {
    flex-direction: column;
  }
  .contact-content {
    text-align: center;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
  .details {
    padding-top: 20px;
  }
  .details-title,
  .contact-content h2 {
    font-size: 28px;
  }
  .form-image {
    content-visibility: hidden;
  }

  .detail-row {
    padding: 20px 20px;
    flex-direction: column;
  }
  .detail-row-2 {
    text-align: left;
  }
  .input-row {
    display: block;
  }

  .input-row input {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .contact-content {
    text-align: center;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
  .details-title,
  .contact-content h2 {
    font-size: 24px;
  }
  .contact-content p,
  .detail-row-1-txt,
  .detail-row-1-txt-2,
  .detail-row-2-txt a {
    font-size: 14px;
  }
  .contact-form button {
    width: 100%;
  }
  .form-image {
    content-visibility: hidden;
  }
}
