/* commoon css */

/* header */
/* .header {
  height: 210px;
  opacity: 1 !important;
} */
/* bread-crumbs */
/* .bread-crumbs {
  text-align: center;
  z-index: 1;
  color: #000;
}
.bread-crumbs a {
  margin: 0px 10px;
}
.bread-crumbs span {
  color: #888;
  margin: 0px 10px;
  text-transform: capitalize;
}
.bread-crumbs ul {
  list-style: none;
  display: inline-block;
  margin-bottom: 0px;
}

.bread-crumbs ul li {
  display: inline;
}

.bread-crumbs ul li a {
  display: block;
  float: left;
  height: 50px;
  background-color: #000;
  text-align: center;
  width: 100px;
  padding-top: 13px;
  position: relative;
  margin: 0 10px 0 0;
  font-size: 20px;
  text-decoration: none;
  color: #8093a7;
}

.bread-crumbs ul li a:after {
  content: "";
  border-top: 26px solid transparent;
  border-bottom: 24px solid transparent;
  border-left: 40px solid #000;
  position: absolute;
  right: -40px;
  top: 0;
  z-index: 1;
}

.bread-crumbs ul li a:before {
  content: "";
  border-top: 26px solid transparent;
  border-bottom: 24px solid transparent;
  border-left: 40px solid #fff;
  position: absolute;
  left: 0;
  top: 0;
}

.bread-crumbs ul li:first-child a {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.bread-crumbs ul li:first-child a::before {
  display: none;
}

.bread-crumbs ul li:last-child a {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.bread-crumbs ul li:last-child a:after {
  display: none;
}

.bread-crumbs ul li a:hover {
  background: #333;
}

.bread-crumbs ul li a:hover:after {
  border-left-color: #333;
  color: #fff;
} */

/* checkout page */

.checkout-page1 .header,
.refund-policy .header {
  opacity: 1 !important;
}

/* .checkout-page1  */
/* .header h1 {
  text-align: left;
  margin-bottom: 30px !important;
} */

/* checkout main content page */
.checkout-content-wrapper {
  color: #333;
  margin-top: 25px;
  margin-bottom: 40px;
  position: relative;
  overflow: visible;
}

.checkout-content-wrapper .form-wrap h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 25px;
  font-weight: bold;
  background-color: #222;
  text-transform: uppercase;
  color: #fff;
  padding: 15px 10px 10px 10px;
  letter-spacing: 1px;
  margin-bottom: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

/* form */
.checkout-content-wrapper .form-inside {
  background-color: #f5f5f5;
  border: 2px solid #f1f1f1;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: none;
  padding: 15px;
}

/* field-wrapper */
.contactField-wrapper {
  margin-bottom: 10px;
}

.contactField-wrapper .form-label {
  color: #000;
  font-weight: bold;
  margin-bottom: 0px;
}
.contactField-wrapper .form-control {
  margin-top: 2px;
}
.contactField-wrapper span {
  font-size: 14px;
  font-weight: 700;
}

/* checkout order summary wrapper */
.checkout-content-wrapper .order-summary-wrapper {
  border: 2px solid rgb(195, 148, 40);
  border-radius: 10px;
  padding: 10px;
}
.checkout-content-wrapper .order-summary-wrapper h2 {
  font-size: 20px;
  text-align: center;
  padding: 5px 0px 0px 0px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0px;
}
.checkout-content-wrapper .order-summary-content {
  margin: 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.order-summary-content .order-summary-product-wrapper {
  border-bottom: 2px solid #333;
  display: flex;
  margin-top: 0px;
  padding: 0px;
  align-items: center;
  padding: 5px 0px;
}
.order-summary-content .order-summary-product-wrapper:last-child {
  border-bottom: 0px;
}
.order-summary-product-wrapper .product-cart-img {
  margin-left: 0px;
}
.order-summary-product-wrapper div {
  text-align: center;
}

.product-cart-img img {
  height: 100%;
}
.cart-item-detail h1 {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  line-height: 130%;
  margin-bottom: 0px;
  text-align: center;
}
.cart-item-detail h6 {
  font-size: 14px;
  margin: 5px 0px;
}
.cart-item-detail p {
  margin-bottom: 3px;
}
.cart-item-detail .variation-button {
  padding: 1px 5px;
  font-size: 14px;
}
.cart-item-qty p i {
  font-size: 12px;
}

/* close cart css */
.order-summary-content span {
  margin-left: 0px;
  left: 15px;
  bottom: 35px;
  font-size: 1rem;
  color: #000;
}
.order-summary-content span {
  position: relative;
  background-color: #bf8e22;
  color: white;
  font-weight: 700;
  font-size: 16px;
  padding: 2px 6px;
  border-radius: 50px;
  left: 92%;
  top: -109px;
}

/* Apply Coupon Code */
.order-summary-wrapper span{
  font-size: 14px;
  font-weight: 700;
  padding: 0px 0px 12px;
  border-bottom: 2px solid #e3e3e3;
  width: 100%;
}
.order-coupon-code {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px 12px;
  border-bottom: 2px solid #e3e3e3;
}
.order-coupon-code input {
  margin: 0px auto 0px 0px;
}
.order-coupon-code .btn-ApplyCouponCode {
  background-color: rgb(195, 148, 40);
  margin: 0px 0px auto 0px;
  padding: 5px 22px;
  color: #fff;
}
.order-coupon-code .btn-ApplyCouponCode:hover,
.order-coupon-code .btn-ApplyCouponCode:focus {
  box-shadow: 0px 0px 7px 0px #000;
}

/* order summary total */
.order-summary-total {
  margin-top: 8px;
}
.order-summary-total div {
  margin-bottom: 0.5rem;
  align-items: center;
}
.order-summary-total .order_title {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0px;
  text-align: left;
}
.order-summary-total p {
  text-align: right;
  margin-bottom: 0px;
  margin-top: -2px;
}
.order-summary-total .order_total_title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 0px;
}

/* payment section */
.order-payment-section {
  border: 2px solid rgb(195, 148, 40);
  padding: 10px;
  border-radius: 10px;
}
.order-payment-section h2 {
  font-size: 22px;
  font-weight: bolder;
}
.payment-logos img.selected {
  border-radius: 10px;
  border: 2px solid rgb(195, 148, 40);
}
.order-payment-content .payment-logos {
  display: flex;
  gap: 20px;
  margin: 20px auto 19px !important;
}

.order-payment-content .payment-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px;
  font-size: 1.1rem;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  margin-top: 6px;
  margin-bottom: 7px;
  width: 100%;
}
.order-payment-content .payment-button:hover {
  box-shadow: 0px 1px 7px 0px #000;
}

/* responsive checkout page */
@media (min-width: 576px) {
  .checkout-page1 {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}
@media (min-width: 992px) {
  .cart-item-detail h1 {
    font-size: 14px;
  }
  .checkout-content-wrapper .form-wrap {
    position: sticky !important;
    top: 12% !important;
    height: 100%;
  }
  /* part-2 checkout page  */
  .checkout-content-wrapper .order-summary-wrapper h2 {
    font-size: 16px;
  }
}

@media (max-width: 1200px) {
  .cart-item-detail h1 {
    font-size: 15px;
  }
  .cart-item-detail h6 {
    font-size: 13px;
  }
}
@media (max-width: 992px) {
  .checkout-page1 {
    padding-top: 40px;
  }
  .order-coupon-code .form-control {
    font-size: 15px;
  }
  .checkout-content-wrapper .form-wrap h2 {
    font-size: 20px;
  }
  .order-coupon-code input {
    width: 62% !important;
  }
}
@media (max-width: 768px) {
  .checkout-content-wrapper .order-summary-wrapper {
    margin-top: 25px;
  }
  .cart-item-detail h1 {
    font-size: 16px;
  }
  .cart-item-detail h6 {
    font-size: 14px;
    padding: 0px 5px;
  }
  .checkout-page1 {
    padding-top: 0px;
  }
}
