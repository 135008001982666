.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Loader-wrapper {
  display: flex;
  align-items: center;
  margin: -50px auto 0px;
  justify-content: center;
  height: 100vh;
  /* width: 100%; */
  animation: blinker 1.5s linear infinite;
  background-color: transparent;
  /* background-color:rgba(0,0,0,0.8); */
}

@keyframes blinker {
  50% {
    opacity: 0;
    /* background-color:rgba(0,0,0,1); */
  }
}

.yarl__container {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

* {
  margin: 0;
  padding: 0;
  user-select: none;
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  padding: 20px 0px 0px 0px;
  margin-top: 140px;
  text-align: left;
}
/* loader css */
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.loader-spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #c39428;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}
.loader-message {
  font-family: Arial, sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #222;
  text-align: center;
  margin-top: 20px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
a {
  text-decoration: none;
  color: #222;
}
.header {
  /* background-image: url(../../public/asset/images/element_22.png); */
  background-image: url(../public/asset/images/element_22.png);
  background-size: cover;
  opacity: 1;
  background-position: right;
  padding: 50px 20px;
  color: #000;
  background-color: aliceblue;
  border-radius: 10px;
  height: 210px;
}

.header h1 {
  text-align: left;
  margin-bottom: 30px;
  font-weight: bold;
  /* cursor: pointer; */
}

/* bread crumbs section */
.bread-crumbs {
  margin: 20px 0px;
  text-align: left;
  /* text-align: center; */
  z-index: 1;
  color: #000;
}
.bread-crumbs a {
  margin: 0px 10px 0px 0px;
  text-decoration: none;
  color: #000;
}
.bread-crumbs i {
  margin: 0px 10px 0px 0px;
}
.bread-crumbs span {
  color: #888;
  margin: 0px 10px 0px 0px;
  text-transform: capitalize;
}
.bread-crumbs ul {
  list-style: none;
  display: inline-block;
  margin-bottom: 0px;
}

.bread-crumbs ul li {
  display: inline;
}

.bread-crumbs ul li a {
  display: block;
  float: left;
  height: 50px;
  background-color: #000;
  text-align: center;
  width: 100px;
  padding-top: 13px;
  position: relative;
  margin: 0 10px 0 0;
  font-size: 20px;
  text-decoration: none;
  color: #8093a7;
}

.bread-crumbs ul li a:after {
  content: "";
  border-top: 26px solid transparent;
  border-bottom: 24px solid transparent;
  border-left: 40px solid #000;
  position: absolute;
  right: -40px;
  top: 0;
  z-index: 1;
}

.bread-crumbs ul li a:before {
  content: "";
  border-top: 26px solid transparent;
  border-bottom: 24px solid transparent;
  border-left: 40px solid #fff;
  position: absolute;
  left: 0;
  top: 0;
}

.bread-crumbs ul li:first-child a {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.bread-crumbs ul li:first-child a::before {
  display: none;
}

.bread-crumbs ul li:last-child a {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.bread-crumbs ul li:last-child a:after {
  display: none;
}

.bread-crumbs ul li a:hover {
  background: #333;
}

.bread-crumbs ul li a:hover:after {
  border-left-color: #333;
  color: #fff;
}

/* .header nav {
  margin: 20px 0px;
  text-align: left;
} */

/* .header nav a {
  text-decoration: none;
  color: #000;
} */

/* .header nav span {
  color: #888;
} */

/* refund and success */
.content {
  margin-top: 3%;
  margin-bottom: 5%;
  text-align: justify;
}
.content h1 {
  color: #222;
  font-weight: bold;
  font-size: 35px;
  line-height: 60px;
}

.content h2 {
  font-family: sans-serif;
  font-size: 22px;
  color: #222;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 25px;
}

.content h3 {
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 5px;
  margin-left: 30px;
  color: #222;
}
.content p {
  margin: 0px 20px 20px;
}

.content ul {
  margin: 15px;
  text-align: left;
}

.content ul {
  list-style: disc;
  margin-left: 50px;
  margin-top: 0px;
}

@media (max-width: 768px) {
  .header h1 {
    margin-left: 10px;
  }
  .header nav {
    margin-left: 10px;
  }

  /* refuand and success page css */
  .content h2 {
    font-size: 22px;
    margin-left: 20px;
  }

  .content h3 {
    margin-left: 25px;
  }

  .content p {
    margin: 20px;
  }

  .content ul {
    margin-left: 40px;
  }
}

@media (max-width: 600px) {
  /* refuand and success page css */
  .content h2 {
    font-size: 20px;
    margin-left: 15px;
  }
  .content h3 {
    margin-left: 25px;
  }
  .content p {
    margin: 15px;
  }
  .content ul {
    margin-left: 30px;
  }
}
@media (max-width: 576px) {
  .header {
    padding: 40px 15px;
    height: 180px;
  }
  .header h1 {
    margin-left: 5px;
    font-size: 24px;
    line-height: 0.7;
  }
  .header nav {
    margin-left: 5px;
  }
  .bread-crumbs a {
    margin: 0px 6px 0px 0px;
  }
  .bread-crumbs i {
    margin: 0px 6px 0px 0px;
  }
  .bread-crumbs span {
    margin: 0px;
  }
  /* refuand and success page css */
  .container {
    padding: 20px 20px 0 20px;
  }
  .content {
    margin-top: 5%;
    margin-bottom: 25%;
  }
  .content h2 {
    font-size: 18px;
    margin-left: 10px;
  }
  .content h3 {
    margin-left: 15px;
    font-size: 17px;
  }
  .content p {
    margin: 10px;
  }

  .content ul {
    margin-left: 20px;
  }
}

@media (max-width: 480px) {
  /* .header h1 {
    font-size: 20px;
  } */
  /* .header nav {
    margin-left: 0;
  } */
  /* refuand and success page css */
  .content h1 {
    font-size: 28px;
  }
  .content h2 {
    font-size: 18px;
    margin-left: 10px;
  }

  .content h3 {
    margin-left: 15px;
  }

  .content p {
    margin: 10px;
  }

  .content ul {
    margin-left: 20px;
  }
}
