/* Watchlist css */
.watchlist-page {
  padding: 20px 0px;
  padding-top: 0px;
}

.wishlist-title {
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 20px;
}

.watchlist-content {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.watchlist-items {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.watchlist-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background: #fff;
}
.watchlist-item-image-wrapper {
  position: relative;
}
.watchlist-item-image {
  width: 100px;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 20px;
  filter: blur(10px);
  transition: filter 0.3s ease;
}
.watchlist-item-image.loaded {
  filter: blur(0);
}

.watchlist-item-details {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.watchlist-item-info {
  display: flex;
  align-items: center;
  gap: 20px;
}

.watchlist-item-link {
  font-size: 18px;
  font-weight: bold;
  color: #222;
  text-decoration: none;
}
.watchlist-item-link:hover {
  color: #c39428;
}
.watchlist-item-price {
  font-size: 18px;
  text-align: center;
  color: #c39428;
  padding: auto;
  font-weight: bold;
}
.wishlist-item-attributes {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}
/* .variation-cart-main {
  display: flex;
  align-items: center;
  gap: 10px;
} */
.variation-cart-main h4 {
  margin: 0;
  font-size: 16px;
  color: #333;
  font-weight: 600;
}

/* .wishlist-item-attributes .variation-buttons, */
.variation-buttons,
.color-options {
  display: flex;
  /* gap: 5px; */
}
.actions {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-left: auto;
}

.watchlist-add-to-cart {
  cursor: pointer;
  padding: 10px 20px;
  background: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
}
.watchlist-add-to-cart.disable-button {
  background-color: grey;
  cursor: not-allowed;
  opacity: 0.5;
}
.watchlist-item-remove {
  cursor: pointer;
  color: #fff;
  border: none;
  font-size: 18px;
  padding: 10px 10px;
  background: #c39428;
  border-radius: 50%;
}

.watchlist-add-to-cart:hover {
  background: #c39428;
  transition: all 0.3s ease-in-out;
}

.watchlist-item-remove:hover {
  background: #333;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 991px) {
  .watchlist-page {
    padding-top: 40px;
  }
  .watchlist-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .watchlist-item-details {
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  .watchlist-item-info {
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
  }
  .watchlist-item-link h5 {
    font-size: 17px;
  }
  .watchlist-item-price {
    font-size: 16px;
  }
  .watchlist-add-to-cart {
    padding: 5px 10px;
    font-size: 15px;
  }
  .watchlist-item-remove {
    padding: 2px 6px 4px 6px;
  }

  .actions {
    margin-left: 0;
    margin-top: 0px;
  }
}

@media (max-width: 768px) {
  .watchlist-page {
    padding-top: 0px;
  }
  .watchlist-item {
    flex-direction: column;
    align-items: center;
  }

  .watchlist-item-details {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 10px;
  }

  .watchlist-item-image {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .watchlist-item-info {
    flex-direction: column;
    align-items: center;
    gap: 3px;
  }

  .wishlist-item-attributes {
    margin-top: 5px;
    align-items: center;
  }
  .watchlist-add-to-cart {
    padding: 10px 20px;
    font-size: 16px;
  }

  .actions {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: 52%;
    margin-top: 10px;
  }
  .watchlist-item-remove {
    padding: 6px 10px 8px 10px;
  }
}

@media (max-width: 600px) {
  /* .watchlist-page {
    padding-top: 100px;
    padding: 0px 20px;
  } */
  /* .watchlist-item {
    flex-direction: column;
    align-items: flex-start;
  } */

  .watchlist-item-details {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .watchlist-item-image {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .watchlist-item-info {
    flex-direction: column;
    align-items: center;
    gap: 3px;
  }

  .actions {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    margin-top: 10px;
  }
}
@media (max-width: 576px) {
  .watchlist-add-to-cart {
    padding: 8px 12px;
    font-size: 15px;
  }
}
@media (max-width: 480px) {
  /* .watchlist-page {
    padding-top: 100px;
  } */
  .watchlist-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .watchlist-item-details {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .watchlist-item-image {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .watchlist-item-info {
    flex-direction: column;
    align-items: center;
    gap: 3px;
  }

  .actions {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    margin-top: 10px;
  }
  .wishlist-item-attributes .variation-buttons {
    margin-left: 0px;
  }
  .wishlist-item-attributes .variation-button {
    padding: 3px 12px;
    font-size: 14px;
  }
}
