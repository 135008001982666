
/* new Product css */
.shop-container {
  padding-top: 140px;
  margin: 20px;
  color: rgb(0, 0, 0);
  text-align: left;
  font-family: sans-serif;
}

.shop-title {
  font-size: 32px;
  margin-bottom: 20px;
  color: rgb(0, 0, 0);
  text-align: left;
  font-weight: bold;
}

.shop-header {
  margin-bottom: 20px;
  margin-top: 25px;
}
/* product category section */
.shop-content {
  display: flex;
  width: 100%;
  gap: 20px;
}
.shop-sidebar-menu {
  flex: 1;
  max-width: 300px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 0px;
  position: sticky;
  top: 80px;
  height: 100%;
  width: 100%;
}
.shop-sidebar {
  width: 100%;
  padding: 20px;
  margin-bottom: 0px;
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.shop-sidebar h3 {
  font-size: 18px;
  margin-bottom: 10px;
}
.shop-sidebar ul {
  list-style: none;
  padding: 0;
}
.shop-sidebar hr {
  margin: 10px 0;
}
.shop-sidebar ul li {
  padding: 10px 0;
  cursor: pointer;
  font-size: 16px;
  border-bottom: 1px solid #e0e0e0;
  transition: color 0.3s ease;
}
.shop-sidebar ul li.active,
.shop-sidebar ul li:hover {
  color: #c89c31;
}
/* product card main content design */
.products-grid {
  flex: 3;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}
.product-card {
  position: relative;
  overflow: hidden;
  text-decoration: none;
  color: #222;
  background: white;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  padding: 12px;
  overflow: unset;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}
.discount-badge {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #ff6347;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  opacity: 1;
  z-index: 2;
}
.product-card-link {
  text-decoration: none;
  color: #222;
  display: block;
  position: relative;
  transition: all 0.3s;
  text-align: center;
}
.product-image {
  opacity: 1;
  padding: 0px;
  width: 100%;
  height: auto;
  filter: blur(10px);
  transition: filter 0.3s ease-in-out, transform 0.3s ease-in-out;
}
.product-image.loaded {
  filter: blur(0);
}
.product-card:hover .product-image {
  transform: none;
}

.product-title {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #333;
  height: 60px;
}

.product-price {
  font-size: 18px;
  font-weight: bold;
  color: #c39428;
  margin-bottom: 10px;
}

.product-card .product-actions {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  visibility: hidden;
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
  margin-left: auto;
  margin-right: auto;
  right: 0%;
  width: 70%;
}

.product-actions button {
  position: relative;
  box-sizing: border-box;
  margin: 0;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  padding: 8px;
  overflow: visible;
}

.product-card .product-actions > button,
.product-card .product-actions .btnProductQuickview {
  transition: transform 0.35s linear;
  transform: scale(0);
  opacity: 0;
}
.product-card .item-product__wishlist svg,
.product-card .btnProductQuickview svg,
.product-card .btn-quick-add svg {
  width: 17px;
}
.product-card .item-product__wishlist,
.product-card .btnProductQuickview,
.product-card .btn-quick-add {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #222;
  background-color: #ffffff;
  border: 1px solid #adb3b4;
}
.product-card .item-product__wishlist {
  font-size: 18px;
  line-height: 1;
}
.product-card .product-actions .disable-button {
  opacity: 0.6 !important;
}
.product-card:hover .product-actions > button,
.product-card:hover .product-actions .btn-quick-add,
.product-card:hover .product-actions .btnProductQuickview {
  transform: scale(1);
  opacity: 1;
}
.product-actions button:hover .item-product__wishlist svg,
.product-actions button:hover .btnProductQuickview svg,
.product-actions button:hover .btn-quick-add svg {
  width: 17px;
}
.product-card:hover .product-actions {
  visibility: visible;
}
.product-card .item-product__wishlist:hover,
.product-card .btnProductQuickview:hover,
.product-card .btn-quick-add:hover,
.product-card .btn-quick-add:hover .product-actions button svg {
  color: #bf8e22 !important;
  background-color: #222;
  border-color: #222;
}
.product-card .disable-button:hover,
.product-card .disable-button:hover .product-actions .disable-button svg {
  background-color: transparent;
  border-color: #adb3b4;
  color: #222 !important;
}
.product-button-main {
  text-decoration: none;
  list-style-type: none;
}
.product-button {
  width: 100%;
  justify-content: center;
  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
  color: rgb(255, 255, 255);
  font-family: sans-serif;
  margin: 20px 0;
  width: -moz-fit-content;
  border: 0;
  background-color: #222;
  padding: 10px 20px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
  letter-spacing: 1px;
  cursor: pointer;
  box-shadow: inset 0 0 0 0 #c89c31;
  transition: ease-out 0.4s;
}

.product-button:hover {
  box-shadow: inset 400px 0 0 0 #c89c31;
}
.product-card:hover .product-image {
  opacity: 0.4;
}
.product-actions button svg:hover {
  color: #bf8e22;
}
.product-actions .disable-button svg:hover {
  color: #222;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
.pagination button {
  margin: 0 5px;
  padding: 10px 15px;
  border: 1px solid #ddd;
  background-color: #fff;
  cursor: pointer;
}
.pagination button:disabled {
  cursor: not-allowed;
  background-color: #f0f0f0;
}
.pagination-list {
  display: flex;
  align-items: center;
}
.paginate_button {
  margin: 0 5px;
}
.page-item.active {
  background-color: #c89c31;
  color: white;
}
.ellipsis {
  margin: 0 5px;
  padding: 10px 15px;
}

@media only screen and (max-width: 2400px) {
  .products-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media only screen and (max-width: 1440px) {
  .products-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 1024px) {
  .products-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 991px) {
  .shop-container {
    padding-top: 70px !important;
    margin: 20px;
  }
  .shop-content {
    flex-direction: row;
    gap: 20px;
  }
  .shop-title {
    font-size: 32px;
  }

  .shop-header {
    flex-direction: row;
    align-items: center;
  }
  .shop-sidebar-menu {
    width: 30%;
  }
  .shop-sidebar {
    padding: 10px;
  }
  .products-grid {
    /* width: 70%; */
    grid-template-columns: repeat(2, 1fr);
  }
  .product-image {
    padding: 0 15px;
  }
  .product-price {
    font-size: 18px;
  }
}
@media only screen and (max-width: 768px) {
  .shop-content {
    flex-direction: column;
  }
  .shop-container {
    padding-top: 0px !important;
  }
  .shop-sidebar-menu {
    position: relative;
    top: 0px;
    max-width: 100%;
  }
  .products-grid {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 600px) {
  /* .shop-title {
    font-size: 28px;
  } */
  .shop-header {
    flex-direction: column;
    align-items: flex-start;
  }

  /* .shop-content {
    flex-direction: column;
  } */
  .products-grid {
    grid-template-columns: repeat(1, 1fr);
    /* grid-template-columns: 1fr; */
  }
  .shop-sidebar-menu,
  .products-grid {
    width: 100%;
    max-width: 1200px;
  }
  .product-title {
    /* height: 50px !important; */
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .product-price {
    margin-bottom: 20px;
  }
}
@media (max-width: 576px) {
  .shop-title {
    font-size: 28px !important;
  }
  .product-button {
    margin: 10px 0;
  }
  .pagination button {
    padding: 5px 12px;
  }
  .ellipsis {
    padding: 5px 10px;
  }
}
@media only screen and(max-width:480px) {
  .shop-sidebar ul {
    display: block; /* Ensure list displays in block for mobile */
  }

  .shop-sidebar ul li {
    padding: 8px; /* Adjust padding for mobile */
  }

  .shop-sidebar ul li.active {
    background-color: #c89c31;
    color: white !important;
  }
  /* Product grid adjustments for mobile */
  .products-grid {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .product-card {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 6px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .product-image {
    max-width: 100%;
    height: auto;
  }

  .product-title {
    font-size: 16px;
    margin: 0px 0 !important;
    text-align: center;
    /* height: 40px !important; */
  }

  .product-price {
    font-size: 15px;
    text-align: center;
  }

  .product-actions {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
  }
  /* Adjust buttons for mobile */
  .product-button,
  .btn-quick-add,
  .item-product__wishlist {
    padding: 10px;
    font-size: 14px;
  }

  /* Pagination buttons */
  .pagination {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
  }
  .pagination-list {
    max-width: 90%;
    overflow-x: auto;
    white-space: nowrap;
  }
  .paginate_button {
    font-size: 10px;
    padding: 5px 5px;
  }
}
/* end new product css */
/* .product-actions button {
  position: relative;
  box-sizing: border-box;
  margin: 0;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  padding: 8px;
  overflow: visible;
} */

/* .sc-egkSDF {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  height: 100vh;
} */

