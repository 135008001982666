.Order-details-info-content {
  /* background-color: #f6f6f6; */
  color: #222;
  padding: 20px;
  border-radius: 10px;
}
.Order-details-title {
  font-weight: bold;
}
.order-date-invoice-item {
  margin-bottom: 10px;
  position: relative;
  letter-spacing: 2px;
}

/* part 1-1 */
.order-detail-content {
  border: 2px solid #222;
  border-radius: 8px;
  padding: 10px;
}

/* .order-detail-content .order-item-title,.Order-summary-details-content h1{
    font-size:28px;
    font-weight: bold;
} */
.order-item-title h1 {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.order-product-title {
  font-size: 18px;
  color: rgb(100, 100, 100);
  font-weight: bold;
}

.order-product-category-title {
  font-size: 16px;
  color: #ccc;
}

.order-item-qty {
  font-weight: bold;
  text-align: center;
}

/* payment method content */
.payment-method-section,
.order-shipping-address {
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  /* background-color: #f6f6f6; */
}

.payment-method-title {
  font-size: 28px;
  font-weight: bold;
}
/* order-summary-details */
.Order-summary-details-content {
  border: 2px solid #222;
  border-radius: 8px;
}

.order-summary-main {
  letter-spacing: 1px;
}

.page-content {
  margin-bottom: 20px;
  margin-top: 10px;
}
.page-content .container {
  max-width: 1350px;
}
.page-content .container .checkout-success {
  background-color: #fff;
  padding: 20px;
  width: 100%;
  font-family: webfont;
}
.page-content .container .navbar {
  padding: 15px;
  background-color: #efefef;
  border-radius: 5px;
  display: block;
  font-size: 18px;
  /* font-weight: bolder; */
}
.page-content .breadcrumb {
  vertical-align: middle;
  align-items: center;
  margin-bottom: 0;
  color: #264882;
}

/* main-content */
#content h1 {
  margin-top: 25px;
}
.content-wrap {
  font-size: 12px;
  color: #000000;
}
.receipt-table {
  width: 680px;
  margin: 0 auto;
}

.receipt-table p {
  margin: 0px 0px 20px 0px;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
}
.receipt-table table {
  border-collapse: collapse;
  width: 100%;
  border-top: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
  margin-bottom: 20px;
}

.receipt-table thead td {
  font-size: 14px;
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  text-align: left;
  background-color: #efefef;
  padding: 7px;
  font-weight: bold;
  color: #222222;
}

.receipt-table tbody td {
  font-size: 14px;
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  text-align: left;
  padding: 7px;
}
.receipt-table tfoot td {
  text-align: right;
  padding: 7px;
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}
.buttons .btn {
  background-color: #152238;
}
/* resize success page */
@media (max-width: 992px) {
  .page-content .container {
    max-width: 1230px;
  }
  .page-content .container .checkout-success {
    padding: 20px 20px 50px;
  }
  .receipt-table {
    width: 670px;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .page-content {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .page-content .container {
    padding: 0;
  }
  .receipt-table {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .page-content .container {
    max-width: 1100px;
  }
  .page-content .container .navbar {
    font-size: 16px;
  }
  /* .receipt-table p{
        overflow: hidden;
        justify-content: center;
        display: inline;
    } */

  /* .receipt-table tbody td{
        padding: 7px;
    } */
}

/* new section/layout design  */
/* .order-details-content{
    width
} */
.payment-method-container,
.shipping-details-container {
  width: 100%;
  box-shadow: -3px 3px 20px 0 #dedede;
  border-radius: 10px;
  /* padding: 10px; */
}
.shipping-details-container {
  margin-top: 20px;
}

.payment-sec-title,
.shipping-detail-title {
  width: 100%;
  position: relative;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}

.payment-sec-title h1,
.shipping-detail-title h1,
.order-item-title h1 {
  background-color: #222;
  color: #f6f6f6;
  margin-bottom: 0px;
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
}

.payment-method-main-content,
.shipping-details-content {
  padding: 10px;
}

.payment-method-main-content h2,
.shipping-details-content h2 {
  font-size: 16px;
  color: rgb(100, 100, 100);
}

.order_items_content {
  /* width: 100%; */
  box-shadow: -3px 3px 20px 0 #dedede;
  /* height: 100%; */
  /* box-shadow: 1px ; */
  /* padding: 10px; */
  border-radius: 10px;
  padding: 0px;
}

.order-summary-details {
  box-shadow: -3px 3px 20px 0 #dedede;
  border-radius: 10px;
  /* padding: 10px; */
}
.order_items_content .row:last-child {
  border-bottom: 0px !important;
  margin-bottom: 20px !important;
}
.order-summary-details h1 {
  background-color: #222;
  color: #f6f6f6;
  margin-bottom: 0px;
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.order-summary-content-main {
  padding: 10px;
  color: #222;
}
.order-details-info-wrap {
  position: sticky;
  z-index: 99;
  top: 0px;
  height: 100%;
}
.order_items_content .row {
  padding: 10px 10px;
  border-bottom: 2px solid #222;
  margin-bottom: 5px;
}
.btn-buy-again:hover {
  background-color: #c39428;
  border: 1px solid #c39428;
  box-shadow: 0px 0px 2px 0px #222;
}
.order-item-price,
.order-item-qty,
.order-item-priceTotal {
  color: rgb(100, 100, 100);
}
.order-summary-main span {
  color: rgb(100, 100, 100);
}
.order-detail-attributes {
  color: rgb(100, 100, 100);
}
.order-detail-attributes h2 {
  font-size: 14px;
  font-weight: bold;
}
.order-detail-attributes span {
  font-size: 14px;
}
@media (min-width: 576px) {
  #order-items {
    padding-right: 0px;
    padding-left: 0px;
  }
}
@media (max-width: 991px) {
  .order-details-info-wrap {
    position: relative;
    z-index: 0;
    top: 0px;
    height: 100%;
    padding: 0px;
  }
  .order_items_content .row {
    padding: 8px 5px 10px 5px;
  }
}
@media (max-width: 576px) {
  .order-product-title {
    font-size: 14px;
  }
  .order-product-category-title {
    font-size: 12px;
  }
  .order_items_content .cart-item-attributes .variation-button {
    padding: 0px 3px !important;
    font-size: 12px !important;
  }
  .order-details-info-wrap {
    z-index: 0;
    top: 0px;
    height: 100%;
    padding: 0px;
  }
  .order_items_content .row .col-2 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .order_items_content .row .col-6 {
    padding-left: 5px;
    padding-right: 5px;
  }
  .order-item-qty,
  .order-item-price,
  .order-item-priceTotal {
    font-size: 13px;
  }
  .order_items_content .row {
    padding: 5px 5px;
  }
  #order-items {
    padding: 0px;
  }
}

/* orderPagination */
/* .form-select{
    width: 25%;
}
@media(max-width:991px){
    .form-select{
        width:40%;
    }
} */
@media (min-width: 576px) {
  .OrderProductTotal,
  .OrderProductRowPage {
    padding-left: 4px;
    padding-right: 4px;
  }
}
@media (min-width: 992px) {
  .row_page {
    width: 130%;
  }
}

.wrapper {
  /* position: relative; */
  /* top: 50%; */
  /* left: 50%; */
  transform: translate(-50%, -50%);
  /* box-shadow: 0 2px 20px 0 rgba(0,0,0,0.5); */
  margin: 0;
  padding: 15px;
  /* background-color: #fff; */
  /* border-radius: 50px; */
}

/* .wrapper li {
  display: inline-block;
  list-style: none;
}

.wrapper li a {
  background-color: #fff;
  text-align: center;
  text-decoration: none;
  color: #333;
  border-radius: 50%;
  margin: 4px;
  box-shadow: inset 0 5px 10px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.5);
  transition: 0.5s;
  display: block;
  width: 25px;
  height: 25px;
  line-height: 25px;
}

.wrapper li a:hover,
.wrapper li a.active {
  color: #fff;
  background-color: #262626;
} */

/* .pagination {
  transform: translate(-50%, -50%);
  margin: 0;
  padding: 15px;
} */
.Orderpagination li {
  display: inline-block;
  list-style: none;
  cursor: pointer;
}
.Orderpagination .page-item:first-child .page-link {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.page-item:last-child .page-link {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
.page-item:not(:first-child) .page-link {
  margin-left: 8px;
}

/* .pagination li a{
  background-color: #fff;
  text-align: center;
  text-decoration: none;
  color: #333;
  border-radius: 50%;
  margin: 4px 8px;
  box-shadow: inset 0 5px 10px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.3);
  transition: 0.5s;
  display: block;
  width: 25px;
  height: 25px;
  line-height: 25px;
  padding: 0px;
} */
.page-link:hover {
  border-color: #c394284d !important;
}
.Orderpagination li.active {
  background-color: transparent;
  color: #e8e8e8;
}
.Orderpagination li.active a {
  background-color: #c39428;
}
.Orderpagination li.active:hover {
  background-color: transparent;
}
.Orderpagination li {
  background-color: transparent;
  color: #222;
}
.pagination li a:hover,
.pagination li a.active,
.pagination li a:focus {
  color: #222;
  background-color: #c39428;
  border-color: #c394284d !important;
}

/* new pagnation css*/
.Orderpagination button,
.Orderpagination div {
  display: inline-block;
  list-style: none;
  cursor: pointer;
  border: 0;
  background-color: transparent;
}
.Orderpagination button span,
.OrderPagination-list button {
  background-color: #fff;
  text-align: center;
  text-decoration: none;
  color: #333;
  border-radius: 50%;
  margin: 4px 5px;
  box-shadow: inset 0 5px 10px rgba(0, 0, 0, 0.12), 0 3px 6px rgba(0, 0, 0, 0.3);
  transition: 0.5s;
  display: block;
  width: 25px;
  height: 25px;
  line-height: 25px;
  padding: 0px;
}

.Orderpagination div button:hover,
.Orderpagination button span:hover {
  color: #222;
  background-color: #c39428;
  border-color: #c394284d !important;
}

.OrderProductTotal{
  color: #222;
}
.row_page h6{
  color: #222;
  font-weight: bold;
}
.row_page .form-select{
  padding: 0.175rem 1.3rem 0.175rem 0.55rem;
  width: 30%;
  background-position: right .35rem center;
  /* backgroundPosition: "right .35rem center", */
}
.row_page .form-select:hover option{
  background-color: #c39428 !important;
}
option:hover{
  background-color: #fff;
}
@media (max-width: 991px) {
  .Orderpagination {
    margin: 12px auto;
  }
  .Orderpagination button span,
  .OrderPagination-list button {
    margin: 4px 6px;
  }
}

@media(max-width:576px){
  .OrderProductRowPage{
    padding-left: 0px;
    padding-right: 6px;
  }
  .row_page h6{
    font-size: 15px;
  }
}