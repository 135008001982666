.terms-container {
  margin: 0px auto 10px;
  font-family: sans-serif;
  color: rgb(100, 100, 100);
  text-align: left;
}
.term-info {
  margin-top: 3%;
  margin-bottom: 5%;
}
.term-info a {
  color: #bf8e22;
  font-weight: bold;
}
.term-info h1 {
  color: #222;
  line-height: 60px;
  font-weight: bold;
  font-size: 35px;
}
.term-info h2 {
  font-family: sans-serif;
  font-size: 32px;
  margin-left: 27px;
  margin-top: 20px;
  font-weight: bold;
}

.term-info h3 {
  margin-top: 40px;
  margin-left: 20px;
  color: #222;
}
.term-info p {
  font-family: sans-serif;
  margin-left: 30px;
  margin-top: 0px;
}

.term-info h4 {
  margin-top: 30px;
  margin-left: 20px;
  color: #222;
}
.term-info ul li {
  margin-left: 40px;
  margin-top: 10px;
}
@media(max-width:991px){
    .terms-container {
        padding-top: 35px;
      }
}
@media(max-width:768px){
    .terms-container {
        padding-top: 0px;
        margin: 0px auto;
      }
      
  .term-info h2 {
    font-size: 28px;
    margin-left: 20px;
  }

  .term-info p {
    margin-left: 25px;
    margin-top: 15px;
  }

  .term-info ul li {
    margin-left: 30px;
  }
    .term-info h3 {
        margin-left: 18px;
        margin-top: 30px;
      }
}

@media(max-width:576px){
    .term-info {
        margin-top: 5%;
        margin-bottom: 25%;
      }
    .term-info h2 {
        font-size: 24px;
        margin-left: 15px;
      }
    
      .term-info p {
        margin-left: 15px;
        margin-top: 10px;
      }
      .term-info h1 {
        font-size: 32px;
      }
      .term-info h3 {
        margin-left: 0px;
        margin-top: 30px;
      }
      .term-info h4 {
        margin-left: 10px;
        margin-top: 20px;
      }
      .term-info ul {
        padding-left: 1.5rem !important;
      }
      .term-info ul li {
        margin-left: 25px;
      }
}
@media(max-width:480px){
    .term-info h2 {
        font-size: 20px;
        margin-left: 10px;
      }
    
      .term-info p {
        margin-left: 10px;
        margin-top: 10px;
      }
    
      .term-info ul li {
        margin-left: 20px;
      }
}