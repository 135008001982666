body {
  font-family: "Roboto", sans-serif;
  color: rgb(205, 205, 205);
  font-size: 16px;
  line-height: 24px;
}

footer {
  background-color: #222;
  padding: 30px 0 0px;
}

.footer-section {
  margin-bottom: 30px;
}
/* .footer-logo {
  width: 300px;
  height: auto;
} */
/* .footer-logo img{
  width: 100%;
  height: 100%;
  display: flex;
} */

.footer-section h3 {
  margin-bottom: 20px;
  color: #bf8e22;
  text-align: left;
}

.footer-section p,
.footer-section ul,
.footer-section a {
  color: rgb(205, 205, 205);
  /* color: rgb(181, 185, 190); */
  text-decoration: none;
  margin-bottom: 10px;
  text-align: left;
  padding-top: 10px;
}

.footer-section .helpline .helpline-number {
  font-size: 30px;
  font-weight: 400;
  color: #fff;
}
.footer-section span {
  display: flex;
}

.footer-social-media {
  display: flex;
  align-items: center;
}
.footer-social-media p {
  margin-right: 5px;
  font-size: 16px;
  line-height: 14px;
  color: rgb(205, 205, 205);
}

.footer-social-media .golden-line {
  content: "";
  display: inline-block;
  width: 20px;
  height: 2px;
  background-color: #bf8e22;
  margin-left: 10px;
  margin-bottom: 3px;
}

.footer-social-media a {
  color: rgb(181, 185, 190);
  margin: 0 5px;
  font-size: large;
  padding-top: 0px;
}

.footer-social-media a:hover {
  color: #ffc107;
}

/* section 2 ,3 */
.footer-section h1 {
  color: #bf8e22;
  font-size: 30px;
  font-weight: bold;
}
.footer-quick-links {
  margin-left: 5px;
  list-style: none;
  padding: 0;
  line-height: 24px;
  padding-top: 0px !important;
}
.footer-quick-links li {
  padding-top: 10px;
}
.footer-quick-links li a:hover {
  color: #bf8e22;
  transition: all 0.3s ease-in-out;
  margin-left: 5px;
}

/* section 4 */
.footer-contact-content {
  display: flex;
  align-items: center;
  padding-top: 10px;
  margin-bottom: 10px;
}
.footer-contact-content span.f-icon {
  border: 1px solid white;
  border-radius: 50px;
  padding: 5px;
  margin-right: 5px;
}

/* footer-bottom */
.footer-bottom {
  /* padding-top: 20px; */
  border-top: 1px solid #303744;
  display: flex;
  justify-content: space-between;
}
.footer-bottom p {
  margin: 0 10px;
  text-align: center;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 15px;
}
@media (min-width: 992px) and (max-width: 1034px) {
  .footer-section .helpline .helpline-number {
    font-size: 28px;
  }
}
@media (min-width: 992px) {
  .footer-logo img {
    width: 95%;
    height: 100%;
    display: flex;
  }
}
@media (max-width: 991px) {
  .footer-section {
    margin-bottom: 15px;
  }
}
@media (max-width: 768px) {
  /* .footer-bottom {
    flex-direction: column;
    align-items: center;
    text-align: center;
  } */
  .footer-bottom p {
    margin: 5px 0;
  }
}
@media (max-width: 576px) {
  .footer-section {
    margin-bottom: 20px;
  }
  .footer-section h1{
    margin-bottom: 0px !important;
  }
  .footer-quick-links li {
    padding-top: 5px;
  }
  .footer-quick-links li a:first-child {
    padding-top: 5px;
  }
  .footer-contact-content {
    padding-inline: 5px;
    margin-bottom: 5px;
  }
  .footer-bottom p {
    margin: 5px 0;
  }
  .footer-social-media p {
    padding-top: 0px;
  }
}
