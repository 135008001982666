/* About Page */
body {
  box-sizing: border-box;
}

.about-main-page {
  height: auto;
  margin-top: 70px;
  display: flex;
  gap: 100px;
}

.about-img-main {
  padding-left: 10%;
  height: auto;
  background-image: url(../../public/asset/images/element_22.png);
  background-position: center;
  background-repeat: no-repeat;
}

.about-img-item-1 {
  width: 100%;
  border-radius: 100px 0 100px 0;
}

.about-img-item-2 {
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  bottom: 150px;
  left: -127px;
  border: 12px solid #ffffff;
  border-radius: 50px;
  box-shadow: 10.87px 40.569px 57px 0px;
}

.about-txt-main {
  width: 50%;
  text-align: left;
  margin-right: 50px;
}

.about-sub-title {
  color: #a3a3a3;
  font-weight: 500;
  padding-bottom: 10px;
}

.about-title {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.4em;
  color: #000;
  text-align: left;
}

.about-txt-p {
  margin-top: 20px;
  padding-right: 20px;
}

.about-txt {
  font-size: 16px;
  color: #000;
  padding-top: 20px;
}

.about-btn {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: rgb(255, 255, 255);
  font-family: Roboto, sans-serif;
  margin: 20px 0;
  border: 0;
  background-color: #222;
  padding: 10px 10px 10px 20px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
  letter-spacing: 1px;
  margin-top: 50px;
  cursor: pointer;
  box-shadow: inset 0 0 0 0 #c89c31;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}

.about-icon {
  font-size: 16px;
  align-items: center;
  display: flex;
  background-color: #c89c31;
  border-radius: 100%;
  padding: 10px;
  border: 1px solid #000;
  box-shadow: inset 0 0 0 0 #333;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}

.about-icon:hover {
  box-shadow: inset 400px 0 0 0 #333;
}
.about-btn:hover {
  box-shadow: inset 400px 0 0 0 #c89c31;
}

.quality-assurance-main {
  width: 100%;
  background-color: #f9f9f9;
  padding: 80px 0;
  text-align: center;
}

.q-sub-title {
  color: #a3a3a3;
  font-weight: 500;
  margin-bottom: 20px;
}

.q-title {
  margin: -5px 0px 0px 0px;
  font-size: 32px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  color: #000;
  margin-bottom: 20px;
}

.q-txt-main {
  padding: 0px 240px 0px 240px;
  margin-bottom: 20px;
  color: #5e5b5b;
}

/* Responsive Styles */
@media(max-width:1399px){
  .about-sub-title {
    padding-bottom: 10px;
    margin-bottom: 0px;
  }
  .about-title{
    line-height: 1.2em;
    margin-bottom: 0px;
  }
  .about-txt {
    padding-top: 5px;
    margin-bottom: 5px;
  }
  .about-btn{
    margin: 20px 0 20px;
  }
}
@media only screen and (max-width: 1200px) {
  .about-main-page {
    gap: 50px;
  }

  .q-txt-main {
    padding: 0px 120px;
  }
}

@media only screen and (max-width: 992px) {
  .about-main-page {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
  .about-sub-title {
    margin-bottom: .5rempx;
  }
  .about-txt {
    padding-top: 10px;
    margin-bottom: 1rem;
  }
 
  .about-btn {
    margin: 30px auto 30px;
  }

  .about-title {
    text-align: center;
    margin-bottom: .5rem;
  }
  .about-img-main {
    padding-left: 0px;
  }

  .about-txt-main {
    width: 80%;
    text-align: center;
    margin: 0 auto;
  }

  .q-txt-main {
    padding: 0px 60px;
  }
}
@media only screen and (max-width: 991px) {
  .about-container {
    padding-top: 40px;
  }
}
@media only screen and (max-width: 768px) {
  .about-container {
    padding-top: 0px;
  }
  .about-main-page {
    gap: 20px;
    margin-top: 40px;
  }
  .about-img-item-2 {
    bottom: 100px;
    left: 0;
  }

  .about-title {
    font-size: 28px;
  }

  .about-btn {
    font-size: 15px;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .about-icon {
    font-size: 15px;
    padding: 6px;
  }

  .q-txt-main {
    padding: 0px 40px;
  }
}

@media only screen and (max-width: 600px) {
  .about-main-page {
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;
  }
  .about-main-page {
    margin-top: 40px;
  }

  .about-img-item-2 {
    bottom: 0px;
    left: 20%;
  }
  .about-txt-main {
    width: 100%;
  }

  .about-txt-p {
    padding-right: 0px;
    margin-top: 10px;
  }

  .about-title {
    font-size: 24px;
  }
  /* .about-btn {
    font-size: 13px;
    margin: 0 auto;
    margin-bottom: 30px;
    margin-top: 30px;
  } */
  /* .about-icon {
    font-size: 13px;
    padding: 6px;
  } */
  .q-txt-main {
    padding: 0px 20px;
  }
}